import Landing from "./landing";
import ProjectsComponent from "./ProjectsComponent";
import SkillsComponent from "./SkillsComponent";
import Contactinfo from "./ContactInfo";
import { BrowserRouter } from "react-router-dom";
const Home = () => {
    return (
        <main>            
                <Landing/>
                <ProjectsComponent/>
                <SkillsComponent/>
                <Contactinfo/>
        </main>
        
      );
}

export default Home;