import {FaGithubSquare} from "react-icons/fa"
import {MdEmail} from "react-icons/md"
import { useRef } from "react"
import {BsLinkedin} from "react-icons/bs"
import emailjs from '@emailjs/browser';

const Contactinfo = () => {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm("service_0w3xrvy", "template_8ilrrqw", form.current, "MfqBExlDh1SWRIbZL")
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
    };
    return(
        <div className="footer" id="contacts">
            <div className="contact-form">
                <h2>Leave a message</h2>    
                <form ref={form} onSubmit={sendEmail} >

                        <label for="fname">Name</label>
                        <input type="text" id="fname" name="firstname" placeholder="Your name.." required/>

                        <label for="email">Email</label>
                        <input type="email" id="email" name="email" placeholder="Your email.." required/>

                        <label for="subject">Subject</label>
                        <textarea id="subject" name="subject" placeholder="Write something.." required></textarea>

                        <input type="submit" value="Submit"/>
                </form>
            </div>
            <div className="socials">
                <h2> Socials</h2>
                    <ul>
                        <li><FaGithubSquare/></li>
                        <li><MdEmail/> </li>
                    </ul>
                    <ul>
                        <li><BsLinkedin/></li>
                    </ul>
                    <p>designed and created by Joakim Tipape.<br/>
                    <span>some rights reserved</span></p>
            </div>
        </div>
    )
}
export default Contactinfo;