
import {BsArrowDownSquareFill} from 'react-icons/bs'
import{HashLink} from "react-router-hash-link"
const Landing = () => {
    return (        
        <div className="landing-section" style={{backgroundImage: `url(./images/bg-image.jpg)`}} id="landing" >
            
            <section className="landing-details">
                <h2>&lt;Hello World, I'm Joakim Tipape/&gt;</h2>
                <p>I am a web developer who cares with great passion about providing great user experience. I build beautiful, interactive and intuitive websites from design to development and deployment.</p>
                
            </section>
            <button className="scrolldown-btn">
                <HashLink smooth to="#projects" ><BsArrowDownSquareFill/></HashLink>
            </button>
        </div>
    )
}

export default Landing;