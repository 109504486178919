const projects = [
    {name : "JTG E-commerce Site",
     id : 1,
     info : "JTG E-commerce is a beautifuly designed, intuitive, easy-to-use e-commerce site that provides great user experience. JTG E-commerce is built with ReactJS, NextJS, HTML, CSS and uses Sanity headless CMS",     
     skills : ["Javasript", "NextJS", "ReactJS", "Sanity CMS" ],
     githublink: "https://github.com/joakimTI/JTG_marketplace",
     livelink: "https://jtg-marketplace.vercel.app/",
     images : ["./images/jtg1.png",
               "./images/jtg2.png",
               "./images/jtg3.png"]
    },
    {name : "projectName",
     id : 2,
     info : "lorem Lorem ipsum, dolor sit amet consectetur adipisicing elit. Culpa non repudiandae aperiam sunt neque at? Eum voluptate recusandae dolorem dignissimos quas harum saepe distinctio maiores. Veritatis architecto iure quia quod.",     
     skills : ["javasript", "HTML", "CSS" ],
     githublink: "https://github.com/joakimTI/JTG_marketplace",
     livelink: "https://jtg-marketplace.vercel.app/",
     images :  ["./images/projectsample.jpg",
                "./images/projectsample.jpg",
                "./images/projectsample.jpg"]
    },
    {name : "projectName",
     id : 3,
     info : "lorem Lorem ipsum, dolor sit amet consectetur adipisicing elit. Culpa non repudiandae aperiam sunt neque at? Eum voluptate recusandae dolorem dignissimos quas harum saepe distinctio maiores. Veritatis architecto iure quia quod.",     
     skills : ["javasript", "HTML", "ReactJs", "CSS", "NodeJs"],
     githublink: "https://github.com/joakimTI/JTG_marketplace",
     livelink: "https://jtg-marketplace.vercel.app/",
     images :  ["./images/projectsample.jpg",
                "./images/projectsample.jpg",
                "./images/projectsample.jpg"]
    }
]

export default projects;