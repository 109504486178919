import {SiJavascript, SiCsswizardry} from "react-icons/si"
import {FaHtml5, FaReact} from "react-icons/fa"
import {BsBootstrapFill} from "react-icons/bs"

const SkillsComponent = () => {
    return (
        <div className="skills-div" id="skills">
            <div className="skills-list">
                <h2> My skills</h2>
                <ul>
                    <li><FaHtml5/> HTML5</li>
                    <li><SiJavascript/> Javascript</li>
                    <li><SiCsswizardry/> CSS</li>
                </ul>
                <ul>
                    <li><FaReact/>React</li>
                    <li><FaReact/>React Native</li>
                </ul>
                <ul>
                    <li><BsBootstrapFill/> Bootstrap</li>
                </ul>
            </div>
            <div className="about-me">
                <h2>about me</h2>
                <p>I am a web developer who's obsessed with providing great user experience.
                    I build beautiful, interactive and intuitive websites from designing to development and deployment.
                </p>
            </div>
            
        </div>
    )
}

export default SkillsComponent;