import projects from "./projects-data";
import {FaExternalLinkAlt} from "react-icons/fa"
import {BsGithub} from "react-icons/bs"
import { useState } from "react";

const ProjectsComponent = () => {
    
        
    const [touchPosition, setTouchPosition] = useState(null)
    const handleTouchStart = (e) => {
        const touchDown = e.touches[0].clientX
        setTouchPosition(touchDown)
        console.log("onTouchStart")
    }
    const handleTouchMove = (e) => {
        const touchDown = touchPosition
    
        if(touchDown === null) {
            return
        }
    
        const currentTouch = e.touches[0].clientX
        const diff = touchDown - currentTouch
    
        if (diff > 10) {
            console.log("moved right")
        }
    
        if (diff < -10) {
            console.log("moved left")
        }
    
        setTouchPosition(null)
    }
    const handleTouchEnd = (e) => {
        console.log("onTouchEnd")
    }

    return(
        <div className="allprojects-div" id="projects">
            <h1>My projects</h1>
            {projects.map((project) => {
                const {name, images, info, skills, id, githublink, livelink} = project;
                return(
                    <div className="project-div">
                    <div key = {id} className = "project-details">
                        <h2>{name}</h2>
                        <p>{info}</p>
                        <ul className="external-links">
                            <li><a href={githublink}><BsGithub/>  Github link</a></li>
                            <li><a href={livelink}><FaExternalLinkAlt/> Live Demo</a></li>
                        </ul>
                        <div className="skills-btns">
                        {skills.map((skill) => {
                            return <button className="skill-btn">{skill}</button>
                        })}
                        </div>
                    </div>
                    <div className="project-images"
                        onTouchStart={handleTouchStart}
                        onTouchMove={handleTouchMove}
                        onTouchEnd={handleTouchEnd}>
                        {images.map((image) => {
                            return <img alt="project display" src={image}></img>
                        })}
                    </div>
                    </div>
                )
            })}
        </div>

    )
}

export default ProjectsComponent;