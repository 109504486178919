
import NavBar from "./navBar";
import Home from "./Home";

import { BrowserRouter } from "react-router-dom";
function App() {
  return (
    <BrowserRouter>
      <NavBar/>
      <Home/>
    </BrowserRouter>    
  );
}

export default App;
