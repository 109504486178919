import{HashLink} from "react-router-hash-link"
import{BiMenuAltRight} from "react-icons/bi"
import { useState } from "react";
import {IoCloseSharp} from "react-icons/io5"
// import {MdHorizontalRule} from "react-icons/md"
// import { useMediaQuery } from 'react-responsive'
// import {TbMinusVertical} from "react-icons/tb"

const NavBar = () => {
    const [style, setStyle] = useState("nav-links");
    const [navStyle, setnavStyle] = useState("nav");
    const [isActive, setisActive] = useState(false);
    const changeStyle = () => {
       if(isActive){
        setisActive(false);   
        setnavStyle("nav");
        setStyle("nav-links"); 
    }
        else{
        setisActive(true);
        setnavStyle("nav-responsive");
        setStyle("nav-links-responsive");
    }     
    };
    

    
    // const isphoneortab = useMediaQuery({query: '(max-width: 950px)'})

    return (
        <section className = "nav-section">            
            <nav className = {navStyle}>
                <HashLink smooth to="#landing"><img src="./JT-logo.png" className="logo"></img></HashLink>
                <ul className = {style}>
                    <li><HashLink smooth to="#projects" >Projects</HashLink></li>
                    {/* {isActive ? <TbMinusVertical/> : <MdHorizontalRule/>} */}
                    <li><HashLink smooth to="#skills" >Skills</HashLink></li>
                    {/* {isActive ? <TbMinusVertical/> : <MdHorizontalRule/>} */}
                    <li><HashLink smooth to="#contacts" >Contact</HashLink></li>
                </ul>                     
            </nav>
            <button onClick={changeStyle} className="burger-btn">
                { !isActive ?  <BiMenuAltRight className="burger-svg"/> : <IoCloseSharp className="close-menu-svg"/>}
            </button>
            
        </section>
    )
}

export default NavBar;